// src/components/Events.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import 'swiper/css'; // Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; // Import only the Pagination module
import 'swiper/css/pagination'; // Import Pagination styles

const Events = () => {
  const [events, setEvents] = useState([]);
  const [countdown, setCountdown] = useState({});

  useEffect(() => {
    let interval;

    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'events');
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs
        .map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            lastDateToRegister: data.lastDateToRegister
              ? new Date(data.lastDateToRegister.seconds * 1000)
              : null,
            eventDate: data.eventDate
              ? new Date(data.eventDate.seconds * 1000)
              : null,
          };
        })
        .filter(event => event.lastDateToRegister) // Filter out events without registration date
        .sort(
          (a, b) =>
            a.lastDateToRegister - b.lastDateToRegister
        ); // Sort by nearest date of last submission

      setEvents(eventList);
      initializeCountdown(eventList);
    };

    const initializeCountdown = (eventList) => {
      const initialTimers = {};
      const now = new Date();
      eventList.forEach(event => {
        const timeLeft = event.lastDateToRegister - now;
        if (timeLeft > 0) {
          initialTimers[event.id] = timeLeft;
        }
      });
      setCountdown(initialTimers);

      interval = setInterval(() => {
        setCountdown(prevCountdown => {
          const newCountdown = { ...prevCountdown };
          Object.keys(newCountdown).forEach(id => {
            newCountdown[id] -= 1000; // Decrement each timer by 1 second
            if (newCountdown[id] <= 0) {
              delete newCountdown[id]; // Remove timer if it reaches 0
            }
          });
          return newCountdown;
        });
      }, 60000); // Update every minute
    };

    fetchEvents();

    return () => clearInterval(interval); // Clear interval on unmount
  }, []);

  const formatTimeLeft = (time) => {
    const totalMinutes = Math.floor(time / (1000 * 60));
    const days = Math.floor(totalMinutes / (60 * 24));
    const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
    const minutes = totalMinutes % 60;
    return `${days}d ${hours}h ${minutes}m`;
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.slice(0, maxLength) + '...'
      : text;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold mb-6 text-center text-gray-800">
        Upcoming Events
      </h2>

      {/* Swiper for mobile devices */}
      <div className="block lg:hidden">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {events.map(event => (
            <SwiperSlide key={event.id}>
              <div className="relative bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105">
                <img
                  src={event.bannerImageUrl}
                  alt={event.title}
                  className="w-full h-48 object-cover"
                />
                <div className="absolute top-0 left-0 bg-green-500 text-white text-sm font-bold px-3 py-1 rounded-br-lg">
                  {countdown[event.id]
                    ? `Registration Closing In: ${formatTimeLeft(countdown[event.id])}`
                    : 'Closed'}
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">
                    {truncateText(event.title, 30)}
                  </h3>
                  <p className="text-gray-700 mb-2">
                    {truncateText(event.description, 100)}
                  </p>
                  <p className="text-gray-600 mb-2">
                    Venue: {event.venue || 'N/A'}
                  </p>
                  <p className="text-gray-600 mb-2">
                    Last Date to Register:{' '}
                    {event.lastDateToRegister
                      ? event.lastDateToRegister.toLocaleDateString()
                      : 'N/A'}
                  </p>
                  <p className="text-gray-600 mb-4">
                    Day of the Event:{' '}
                    {event.eventDate
                      ? event.eventDate.toLocaleDateString()
                      : 'N/A'}
                  </p>
                  <a
                    href={`/view/${event.id}`}
                    className="block bg-green-600 text-white text-center px-4 py-2 rounded-md hover:bg-green-700 transition duration-200"
                  >
                    View
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Grid layout for large screens */}
      <div className="hidden lg:grid lg:grid-cols-4 lg:gap-6">
        {events.map(event => (
          <div
            key={event.id}
            className="relative bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
          >
            <img
              src={event.bannerImageUrl}
              alt={event.title}
              className="w-full h-48 object-cover"
            />
            <div className="absolute top-0 left-0 bg-green-500 text-white text-sm font-bold px-3 py-1 rounded-br-lg">
              {countdown[event.id]
                ? `Registrations Closing In: ${formatTimeLeft(countdown[event.id])}`
                : 'Closed'}
            </div>
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">
                {truncateText(event.title, 30)}
              </h3>
              <p className="text-gray-700 mb-2">
                {truncateText(event.description, 80)}
              </p>
              <p className="text-gray-600 mb-2">
                Venue: {event.venue || 'N/A'}
              </p>
              <p className="text-gray-600 mb-2">
                Last Date to Register:{' '}
                {event.lastDateToRegister
                  ? event.lastDateToRegister.toLocaleDateString()
                  : 'N/A'}
              </p>
              <p className="text-gray-600 mb-4">
                Day of the Event:{' '}
                {event.eventDate
                  ? event.eventDate.toLocaleDateString()
                  : 'N/A'}
              </p>
              <a
                href={`/view/${event.id}`}
                className="block bg-green-600 text-white text-center px-4 py-2 rounded-md hover:bg-green-700 transition duration-200"
              >
                View
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;