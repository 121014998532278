// src/firebase.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAzdGbjfKtyJePO7PTZiyDe0x1jjmd4Zv8",
  authDomain: "youthxconnect-site.firebaseapp.com",
  projectId: "youthxconnect-site",
  storageBucket: "youthxconnect-site.appspot.com",
  messagingSenderId: "53765282807",
  appId: "1:53765282807:web:f8057a868504a11ffb6cf2",
  measurementId: "G-1HMHGKZ5K0"
};

// Initialize Firebase only if no apps are initialized
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

// Export the initialized services
export { app, db, storage };
