// src/pages/AboutUs.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import MentorCard from '../components/MentorCard';
import ProjectCard from '../components/ProjectCard';
import FounderNote from '../components/FounderNote';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';

// Import Pillar Project Images
import Pillar1 from '../assets/pillar-projects/1.png';
import Pillar2 from '../assets/pillar-projects/2.png';
import Pillar3 from '../assets/pillar-projects/3.png';
import Pillar4 from '../assets/pillar-projects/4.png';
import Pillar5 from '../assets/pillar-projects/5.png';
import Pillar6 from '../assets/pillar-projects/6.png';

// Import Mentor Images
import portfolio01 from '../assets/mentors/portfolio-01.jpeg';
import portfolio02 from '../assets/mentors/portfolio-02.jpeg';
import portfolio03 from '../assets/mentors/portfolio-03.jpeg';
import portfolio04 from '../assets/mentors/portfolio-04.jpeg';
import portfolio05 from '../assets/mentors/portfolio-05.jpeg';
import portfolio06 from '../assets/mentors/portfolio-06.jpeg';
import portfolio07 from '../assets/mentors/portfolio-07.jpeg';
import portfolio08 from '../assets/mentors/portfolio-08.jpeg';
import portfolio09 from '../assets/mentors/portfolio-09.jpeg';
import portfolio10 from '../assets/mentors/portfolio-10.jpeg';
import portfolio11 from '../assets/mentors/portfolio-11.jpeg';
import portfolio12 from '../assets/mentors/portfolio-12.jpeg';
import portfolio13 from '../assets/mentors/portfolio-13.jpeg';
import portfolio14 from '../assets/mentors/portfolio-14.jpeg'; // Newly added

// Create a Mapping of Image Filenames to Imported Images
const mentorImages = {
  'portfolio-01.jpeg': portfolio01,
  'portfolio-02.jpeg': portfolio02,
  'portfolio-03.jpeg': portfolio03,
  'portfolio-04.jpeg': portfolio04,
  'portfolio-05.jpeg': portfolio05,
  'portfolio-06.jpeg': portfolio06,
  'portfolio-07.jpeg': portfolio07,
  'portfolio-08.jpeg': portfolio08,
  'portfolio-09.jpeg': portfolio09,
  'portfolio-10.jpeg': portfolio10,
  'portfolio-11.jpeg': portfolio11,
  'portfolio-12.jpeg': portfolio12,
  'portfolio-13.jpeg': portfolio13,
  'portfolio-14.jpeg': portfolio14, // Added mapping
  // Add more mappings as needed
};

// Convert mentorImages to an array for random selection
const mentorImagesArray = Object.values(mentorImages);

// Placeholder Mentors (Displayed When Firebase Data is Unavailable)
const placeholderMentors = Array.from({ length: 14 }, (_, index) => ({
  id: index.toString(),
  name: `Mentor ${index + 1}`,
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vel dolor ac libero condimentum laoreet.`,
  image: mentorImagesArray[index % mentorImagesArray.length], // Assign images in order or randomly
}));

const AboutUs = () => {
  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const mentorsCollection = collection(db, 'mentors');
        const mentorsSnapshot = await getDocs(mentorsCollection);
        const mentorsList = mentorsSnapshot.docs.map((doc) => {
          const data = doc.data();
          const imagePath = data.image; // Should be like '/assets/mentors/portfolio-01.jpeg'

          // Check if imagePath is a URL
          const isURL = (str) => {
            try {
              new URL(str);
              return true;
            } catch (_) {
              return false;
            }
          };

          let image;
          if (isURL(imagePath)) {
            image = imagePath; // Use URL directly
          } else {
            // Assign image from mentorImages based on filename
            const filename = imagePath.startsWith('/') ? imagePath.slice(1) : imagePath; // Remove leading slash if present
            image = mentorImages[filename] || `https://via.placeholder.com/720x1280?text=${encodeURIComponent(data.name)}`;
          }

          console.log(`Mentor: ${data.name}, Image: ${image}`);

          return {
            id: doc.id,
            ...data,
            image,
          };
        });
        setMentors(mentorsList);
      } catch (error) {
        console.error('Error fetching mentors:', error);
        setMentors([]);
      }
    };

    fetchMentors();
  }, []); // Added empty dependency array

  const pillarProjects = [
    {
      id: '1',
      name: 'Youth Parliament',
      description:
        'Youth parliaments serve as dynamic platforms engaging young individuals in democratic processes. Providing a forum for discussions, solution proposals, and simulated parliamentary sessions, they empower youth, nurture leadership, and encourage civic participation. These initiatives aim to instill a sense of responsibility and active citizenship in the younger generation.',
      detailedDescription:
        'The Youth Parliament aims to offer a vibrant and inclusive platform for young individuals to actively engage in the democratic process, express their thoughts on social, political, and economic issues, and foster leadership, civic education, and advocacy. It empowers the next generation, equipping them with skills for positive societal contributions.',
      image: Pillar1, // Imported Image
      link: '#',
    },
    {
      id: '2',
      name: 'Youth Investment Forum',
      description:
        'Youth Investment Forums empower young individuals to explore diverse investment opportunities, fostering financial literacy, entrepreneurship, and strategic investment skills. With expert speakers, workshops, and networking, participants gain valuable insights for informed decision-making, contributing to their economic growth and development.',
      detailedDescription:
        'Youth Investment Forums aim to empower young individuals by imparting financial knowledge, skills, and resources, fostering confidence in investment decisions. Through education, networking, and exposure to opportunities, these forums inspire entrepreneurship and contribute to economic growth within communities.',
      image: Pillar2, // Imported Image
      link: '#',
    },
    {
      id: '3',
      name: 'Knowledge Tour',
      description:
        'Knowledge tours are curated educational experiences that enhance participants\' understanding of diverse subjects or locations. Led by experts, these journeys encompass visits to museums, historical sites, universities, and cultural landmarks. Through interactive learning and discussions, participants gain insights and expertise, fostering a profound appreciation for a range of topics and encouraging a commitment to lifelong learning.',
      detailedDescription:
        'A knowledge tour aims to enhance learning through hands-on experiences, guided tours, and expert discussions. Fostering curiosity and critical thinking, these tours encourage lifelong learning, personal growth, and a deeper appreciation for the world\'s diversity and complexity.',
      image: Pillar3, // Imported Image
      link: '#',
    },
    {
      id: '4',
      name: 'Ideathon',
      description:
        'An ideathon is a collective gathering promoting creativity and problem-solving. Participants collaborate to generate innovative ideas and solutions for specific challenges or opportunities. In a set time frame, they brainstorm, pitch, and develop ideas, vying for recognition and support. Ideathons also facilitate networking and the transformation of concepts into actionable projects or businesses.',
      detailedDescription:
        'Ideathon aims to unite diverse minds, promoting innovation to address challenges or uncover opportunities. Participants collaborate to generate and refine ideas, working towards actionable solutions. The goal is to identify innovative concepts with positive impacts in technology, business, and social or environmental realms.',
      image: Pillar4, // Imported Image
      link: '#',
    },
    {
      id: '5',
      name: 'Network and Knowledge Exchange Program',
      description:
        'A knowledge-sharing initiative promotes collaboration, innovation, and learning among participants from diverse backgrounds and organizations. Through workshops, conferences, and collaborative projects, it cultivates expertise exchange, fostering growth and development for individuals and communities involved.',
      detailedDescription:
        'The goal of a network and knowledge exchange initiative is to encourage collaboration, facilitate the exchange of expertise and ideas, promote innovation, and provide learning opportunities for participants from diverse backgrounds and organizations. Through workshops, conferences, and joint projects, the program aims to contribute to the growth and development of individuals and communities, fostering positive change and progress.',
      image: Pillar5, // Imported Image
      link: '#',
    },
    {
      id: '6',
      name: 'Activities on Discord',
      description:
        'A vibrant community platform where members engage in various interactive activities, fostering collaboration, networking, and continuous learning. Through channels dedicated to discussions, workshops, Q&A sessions, and social events, our Discord server serves as a hub for real-time communication and support among participants.',
      detailedDescription:
        'The Activities on Discord initiative aims to create an engaging and interactive online community where members can collaborate, network, and learn continuously. By hosting dedicated channels for discussions, workshops, Q&A sessions, and social events, the Discord server facilitates real-time communication, support, and knowledge sharing. This platform not only enhances participant experience but also fosters a sense of belonging and active involvement in our community.',
      image: Pillar6, // Imported Image
      link: '#',
    },
  ];

  // Function to get random image from mentorImagesArray
  const getRandomImage = () => {
    return mentorImagesArray[Math.floor(Math.random() * mentorImagesArray.length)];
  };

  // Assign random images to mentors if mentors from Firebase are not available
  const mentorsToDisplay =
    mentors.length > 0
      ? mentors
      : placeholderMentors.map((mentor) => ({
          ...mentor,
          image: getRandomImage(),
        }));

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        {/* Essential Meta Tags for Responsive Design */}
        <meta charSet="utf-8" />
        <title>About Us - YouthXConnect</title>
        <meta
          name="description"
          content="Learn more about YouthXConnect's mission, vision, and the comprehensive programs we offer to empower youth leadership and innovation."
        />
        <meta
          name="keywords"
          content="Youth, Leadership, Innovation, Service, YouthXConnect, Mentorship, Projects, Programs"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="About Us - YouthXConnect" />
        <meta
          property="og:description"
          content="Learn more about YouthXConnect's mission, vision, and the comprehensive programs we offer to empower youth leadership and innovation."
        />
        {/* Use Absolute URLs for Images */}
        <meta property="og:image" content="https://www.youthxconnect.com/images/aboutus-og.png" />
        <meta property="og:url" content="https://www.youthxconnect.com/about" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="About Us - YouthXConnect" />
        <meta
          name="twitter:description"
          content="Learn more about YouthXConnect's mission, vision, and the comprehensive programs we offer to empower youth leadership and innovation."
        />
        <meta name="twitter:image" content="https://www.youthxconnect.com/images/aboutus.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Navbar />
      {/* Adding padding to ensure content is not overlapped by the Navbar */}
      <main className="flex-grow pt-24 bg-gray-50">
        <motion.div
          className="container mx-auto px-4 sm:px-6 lg:px-8 py-12"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          {/* About Us Header */}
          <section className="text-center mb-12">
            <motion.h1
              className="text-4xl sm:text-5xl font-extrabold tracking-tight text-gray-900"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              About Us
            </motion.h1>
            <motion.p
              className="mt-6 text-base sm:text-lg text-gray-600 max-w-3xl mx-auto px-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              YouthXConnect is committed to fostering leadership, innovation, and service among young individuals. Our comprehensive programs and initiatives empower youth to become confident and visionary leaders who drive positive change.
            </motion.p>
          </section>

          {/* Pillar Projects Section */}
          <section className="mt-16">
            <motion.h2
              className="text-3xl sm:text-4xl font-bold text-gray-900 mb-12 text-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Pillar Projects
            </motion.h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12">
              {pillarProjects.map((project) => (
                <motion.div
                  key={project.id}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                  <ProjectCard project={project} />
                </motion.div>
              ))}
            </div>
          </section>

          {/* Note from Founder Section */}
          <FounderNote />

          {/* Our Mentors Section */}
          <section className="mt-24">
            <motion.h2
              className="text-3xl sm:text-4xl font-bold text-gray-900 mb-12 text-center"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              Our Mentors
            </motion.h2>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              spaceBetween={30}
              slidesPerView={1}
              loop={true}
              breakpoints={{
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
              }}
              className="mySwiper"
            >
              {mentorsToDisplay.map((mentor) => (
                <SwiperSlide key={mentor.id}>
                  <MentorCard mentor={mentor} />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
        </motion.div>
      </main>
      
    </div>
  );
};

export default AboutUs;
