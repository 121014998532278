// src/components/AboutSection.js
import React from 'react';
import { Link } from 'react-router-dom';

const AboutSection = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-center">
      <p className="text-lg mb-4">
        YouthXConnect is an organization dedicated to empowering youth, fostering a culture of leadership, innovation, and service. With a vision to unleash the potential of every young individual, we provide diverse programs and initiatives that enable young leaders to connect, collaborate, and create positive change in their communities.
      </p>
      <Link to="/join">
        <button className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600">
          Join Us
        </button>
      </Link>
    </div>
  );
};

export default AboutSection;
