import React from 'react';

const ProjectCard = ({ project }) => {
  return (
    <div className="bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300 overflow-hidden transform hover:-translate-y-2">
      {project.image && (
        <img
          src={project.image}
          alt={project.name}
          className="w-full h-56 object-cover"
        />
      )}
      <div className="p-6">
        <h3 className="text-2xl font-semibold text-gray-800 mb-3">
          {project.name}
        </h3>
        <p className="text-gray-600 mb-4">{project.description}</p>
        <a
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 hover:text-indigo-900 font-medium inline-flex items-center"
        >
          
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
