// src/pages/PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy - YouthXConnect</title>
        <meta
          name="description"
          content="Read YouthXConnect's Privacy Policy to understand how we collect, use, and protect your personal information."
        />
        <meta
          name="keywords"
          content="Privacy Policy, YouthXConnect, Data Protection, Personal Information, Privacy"
        />
        <meta property="og:title" content="Privacy Policy - YouthXConnect" />
        <meta
          property="og:description"
          content="Read YouthXConnect's Privacy Policy to understand how we collect, use, and protect your personal information."
        />
        <meta property="og:image" content="%PUBLIC_URL%/privacy-policy-og.png" />
        <meta property="og:url" content="https://www.youthxconnect.com/privacy-policy" />
        <meta name="twitter:title" content="Privacy Policy - YouthXConnect" />
        <meta
          name="twitter:description"
          content="Read YouthXConnect's Privacy Policy to understand how we collect, use, and protect your personal information."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/privacy-policy-twitter.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <motion.div
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-20 max-w-4xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="text-3xl sm:text-4xl font-bold mb-6 text-center text-gray-900"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Privacy Policy
        </motion.h1>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
          Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect from you across our website, <strong>YouthXConnect</strong>, and other sites we own and operate.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
        >
          Information We Collect
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
          We may collect personal information such as your name, email address, phone number, and other relevant details when you register on our site or engage with our services.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6 }}
        >
          Use of Information
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7 }}>
          We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to ensure the security of our platform.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.8 }}
        >
          Data Security
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.9 }}>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.0 }}
        >
          Legal Compliance
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.1 }}>
          We may disclose your information where required by law or to protect our rights. This includes complying with local laws in India, responding to legal requests, and enforcing our terms of service.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.2 }}
        >
          Changes to This Policy
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.3 }}>
          We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
        </motion.p>
        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.4 }}
        >
          Contact Us
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:connect@youthxconnect.com" className="text-blue-500 underline">connect@youthxconnect.com</a>.
        </motion.p>
      </motion.div>
      
    </div>
  );
};

export default PrivacyPolicy;
