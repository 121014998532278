import React from 'react';
import founderImage from '../assets/founder.jpg'; // Replace with the actual path to the founder's image

const FounderNote = () => {
  return (
    <section className="mt-24">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl p-8 md:p-12">

        {/* SVG Icon */}
        <svg
          className="mx-auto mb-6 h-12 w-12 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 18 14"
          aria-hidden="true"
        >
          <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
        </svg>

        {/* Founder Message */}
        <blockquote className="text-center">
          <p className="text-lg font-medium text-gray-900">
            "Welcome to YouthXConnect! Our mission is to empower the next generation of leaders, innovators, and changemakers. We believe in the boundless potential of youth and are committed to providing the resources, support, and opportunities necessary for you to thrive. Together, we can drive positive change and build a brighter future."
          </p>
          <p className="mt-4 text-lg font-medium text-gray-900">
            "Join us on this incredible journey of growth, collaboration, and impact. Let's connect, innovate, and lead with passion and purpose."
          </p>
        </blockquote>

        {/* Founder Info */}
        <div className="mt-8 flex items-center justify-center space-x-4">
        <img
  className="h-16 w-16 rounded-full object-cover"
  src={founderImage}
  alt="Rishabh Jain, Founder and CEO"
/>

          <div className="text-left">
            <cite className="block text-xl font-semibold text-gray-900">Rishabh Jain</cite>
            <span className="text-sm text-gray-500">Founder & CEO at YouthXConnect</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FounderNote;
