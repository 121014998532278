// src/components/MottoSection.js
import React from 'react';
import mottoImage from '../assets/our-motto.png'; // Ensure your motto image is correctly placed in src/assets/

const MottoSection = () => {
  return (
    <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2">
        <img src={mottoImage} alt="Our Motto" className="w-full h-auto rounded-lg" />
      </div>
      <div className="md:w-1/2 md:pl-8 mt-6 md:mt-0">
        <p className="text-lg mb-4">
          At YouthXConnect, we believe in the boundless potential of every young individual. Our mission is to foster leadership, innovation, and community service among youth, empowering them to become the changemakers of tomorrow. Through a variety of programs, workshops, and mentorship opportunities, we provide the tools and support necessary for young minds to thrive. We are committed to creating a nurturing environment where creativity and collaboration flourish, enabling our members to tackle real-world challenges with confidence and resilience. Join us in our journey to build a brighter, more inclusive future where every young person has the opportunity to excel and make a meaningful impact.
        </p>
        <div className="text-center">
          {/* <button className="bg-purple-500 text-white px-6 py-2 rounded-md hover:bg-purple-600">
            Donate Us
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default MottoSection;
