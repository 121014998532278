// src/pages/TermsOfService.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

const TermsOfService = () => {
  return (
    <div>
      <Helmet>
        <title>Terms of Service - YouthXConnect</title>
        <meta
          name="description"
          content="Read the Terms of Service for YouthXConnect to understand the rules and regulations governing the use of our website and services."
        />
        <meta
          name="keywords"
          content="Terms of Service, YouthXConnect, Terms, Conditions, User Agreement"
        />
        <meta property="og:title" content="Terms of Service - YouthXConnect" />
        <meta
          property="og:description"
          content="Read the Terms of Service for YouthXConnect to understand the rules and regulations governing the use of our website and services."
        />
        <meta property="og:image" content="%PUBLIC_URL%/terms-of-service-og.png" />
        <meta property="og:url" content="https://www.youthxconnect.com/terms-of-service" />
        <meta name="twitter:title" content="Terms of Service - YouthXConnect" />
        <meta
          name="twitter:description"
          content="Read the Terms of Service for YouthXConnect to understand the rules and regulations governing the use of our website and services."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/terms-of-service-twitter.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <motion.div
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-20 max-w-4xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="text-3xl sm:text-4xl font-bold mb-6 text-center text-gray-900"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Terms of Service
        </motion.h1>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
          Last updated: October 31, 2024
        </motion.p>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
          Welcome to <strong>YouthXConnect</strong>. By accessing our website, you agree to comply with and be bound by the following terms and conditions.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5 }}
        >
          Use of the Site
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}>
          You agree to use the site only for lawful purposes and in a way that does not infringe the rights of others or restrict their use and enjoyment of the site.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.7 }}
        >
          Intellectual Property
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.8 }}>
          All content, trademarks, and data on this site are the property of <strong>YouthXConnect</strong> and are protected by Indian and international intellectual property laws.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9 }}
        >
          User Responsibilities
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.0 }}>
          Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.1 }}
        >
          Limitation of Liability
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.2 }}>
          <strong>YouthXConnect</strong> shall not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of the site.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.3 }}
        >
          Governing Law
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.4 }}>
          These terms are governed by and construed in accordance with the laws of India. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in India.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.5 }}
        >
          Changes to Terms
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.6 }}>
          <strong>YouthXConnect</strong> reserves the right to modify these terms at any time. Changes will be effective immediately upon posting on the site.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.7 }}
        >
          Contact Information
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.8 }}>
          For any questions regarding these Terms of Service, please contact us at <a href="mailto:connect@YouthXConnect.com" className="text-blue-500 underline">connect@YouthXConnect.com</a>.
        </motion.p>
      </motion.div>
      
    </div>
  );
};

export default TermsOfService;
