// src/components/MentorCard.js
import React from "react";

const MentorCard = ({ mentor }) => {
  return (
    <div className="bg-white rounded-3xl shadow-xl overflow-hidden transform transition-transform duration-300 hover:scale-105">
      <div className="relative">
        <img
          src={mentor.image}
          alt={mentor.name}
          className="w-full h-54 sm:h-72 md:h-80 lg:h-96 object-cover"
          loading="lazy"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/720x1280?text=No+Image";
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60"></div>
        <div className="absolute bottom-0 p-6">
          {/* <h3 className="text-2xl font-semibold text-white">{mentor.name}</h3>
          <p className="text-sm text-gray-300 mt-2">{mentor.description}</p> */}
        </div>
      </div>
    </div>
  );
};

export default MentorCard;
