// src/pages/RefundPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';

const RefundPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Refund Policy - YouthXConnect</title>
        <meta
          name="description"
          content="Learn about YouthXConnect's Refund Policy, including eligibility, non-refundable situations, and the refund process."
        />
        <meta
          name="keywords"
          content="Refund Policy, YouthXConnect, Refunds, Cancellations, Policies"
        />
        <meta property="og:title" content="Refund Policy - YouthXConnect" />
        <meta
          property="og:description"
          content="Learn about YouthXConnect's Refund Policy, including eligibility, non-refundable situations, and the refund process."
        />
        <meta property="og:image" content="%PUBLIC_URL%/refund-policy-og.png" />
        <meta property="og:url" content="https://www.youthxconnect.com/refund-policy" />
        <meta name="twitter:title" content="Refund Policy - YouthXConnect" />
        <meta
          name="twitter:description"
          content="Learn about YouthXConnect's Refund Policy, including eligibility, non-refundable situations, and the refund process."
        />
        <meta name="twitter:image" content="%PUBLIC_URL%/refund-policy-twitter.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <motion.div
        className="container mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-20 max-w-4xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          className="text-3xl sm:text-4xl font-bold mb-6 text-center text-gray-900"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6 }}
        >
          Refund Policy
        </motion.h1>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
          Last updated: October 31, 2024
        </motion.p>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
          At <strong>YouthXConnect</strong>, we strive to ensure the satisfaction of our participants. This Refund Policy outlines the circumstances under which refunds are granted.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5 }}
        >
          Eligibility for Refunds
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}>
          Refunds are only available if an event is cancelled by us. In such cases, participants will be notified via the email address provided during registration.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.7 }}
        >
          Non-Refundable Situations
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.8 }}>
          Refunds will not be provided for:
          <ul className="list-disc list-inside ml-4 mt-2">
            <li>Cancellations made by the participant.</li>
            <li>No-shows or failure to attend the event.</li>
            <li>Change of mind after registration.</li>
          </ul>
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.9 }}
        >
          Refund Process
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.0 }}>
          If an event is cancelled by us, refunds will be processed within 14 business days using the original payment method. Please allow additional time for your bank or credit card company to process the refund.
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.1 }}
        >
          Contact Us
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.2 }}>
          For any refund-related inquiries, please contact our support team at <a href="mailto:connect@YouthXConnect.com" className="text-blue-500 underline">connect@YouthXConnect.com</a>.
        </motion.p>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.3 }}>
          <strong>Grievance Complaints Contact Address:</strong><br />
          Mr. Rishabh Jain,<br />
          5421231, Indira Marg,<br />
          East Delhi, Mandawali,<br />
          Fazalpur, Laxmi Nagar,<br />
          Delhi, India, 110092
        </motion.p>

        <motion.h2
          className="text-2xl sm:text-2xl font-semibold mt-6 mb-4 text-gray-800"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 1.4 }}
        >
          Amendments
        </motion.h2>
        <motion.p className="mb-4 text-base sm:text-lg text-gray-700" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
          We reserve the right to amend this Refund Policy at any time. All changes will be posted on this page with an updated revision date.
        </motion.p>
      </motion.div>
      
    </div>
  );
};

export default RefundPolicy;