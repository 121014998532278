// src/components/Banner.js
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import bannerImage from "../assets/ids.png";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link 
      to="https://ids.youthxconnect.com"
      aria-label="Navigate to Indian Diplomacy Summit"
    >
      <motion.section
        className="relative mt-16 sm:mt-16 md:mt-16 lg:mt-16 w-full h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] 
                   flex items-center justify-center bg-gradient-to-r from-gray-900 to-gray-800 overflow-hidden 
                   rounded-xl mx-auto max-w-[2000px] shadow-2xl"
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Loading Overlay */}
        <AnimatePresence>
          {isLoading && (
            <motion.div
              className="absolute inset-0 bg-gray-900 z-20 flex items-center justify-center"
              exit={{ opacity: 0 }}
            >
              <div className="w-12 h-12 border-4 border-white border-t-transparent rounded-full animate-spin" />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Background Image */}
        <motion.img
          src={bannerImage}
          alt="Indian Diplomacy Summit Banner"
          className="absolute inset-0 w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ 
            scale: isHovered ? 1.05 : 1,
          }}
          transition={{ 
            duration: 1.5, 
            ease: "easeOut" 
          }}
          onLoad={() => setIsLoading(false)}
        />

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-black/30" />

        {/* Content Overlay */}
        <motion.div
          className="z-10 text-center px-4 max-w-4xl"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <motion.h1
            className="text-3xl md:text-5xl lg:text-6xl font-bold text-white mb-4 drop-shadow-lg"
            animate={{ scale: isHovered ? 1.05 : 1 }}
            transition={{ duration: 0.3 }}
          >
            Indian Diplomacy Summit 2024
          </motion.h1>
          <motion.p
            className="text-lg md:text-xl text-gray-200 max-w-2xl mx-auto leading-relaxed hidden md:block"
            animate={{ y: isHovered ? -5 : 0 }}
            transition={{ duration: 0.3 }}
          >
            Join us for an enlightening discourse on international relations and diplomatic excellence
          </motion.p>
          <motion.button
            className="mt-6 px-8 py-3 bg-white text-gray-900 rounded-full font-semibold 
                     hover:bg-opacity-90 transition-all duration-300 transform"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More
          </motion.button>
        </motion.div>

        {/* Interactive Elements Indicator */}
        <motion.div
          className="absolute bottom-4 right-4 text-white text-sm flex items-center gap-2 opacity-60"
          animate={{ opacity: isHovered ? 1 : 0.6 }}
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-5 w-5" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
          </svg>
          <span>Interactive Experience</span>
        </motion.div>
      </motion.section>
    </Link>
  );
};

export default Banner;